import { Flex, Typography, pxToRem, styled, NavigationLocation } from '@genialcare/atipico-react';

export const Root = styled(Flex, {});

export const Text = styled(Typography, {
  variants: {
    variant: {
      body2: {
        fontSize: pxToRem(14),
        lineHeight: pxToRem(20),
        color: '#999999',
        maxWidth: pxToRem(150),
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
});

export const Icon = styled(NavigationLocation, {
  width: pxToRem(20),
  height: pxToRem(20),
  fill: '#999999',
});
