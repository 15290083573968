import React, { useRef, useState, useEffect } from 'react';
import {
  InputGroup,
  InputRightElement,
  InterfaceCross,
  Typography,
} from '@genialcare/atipico-react';
import { useMergeRefs } from 'hooks/useMergeRefs';
import * as S from './InputWithList.styles';
import { useTranslation } from 'react-i18next';

type InputWithListProps = {
  label?: string;
  isInvalid?: boolean;
  isInvalidInput?: boolean;
  isDisabled?: boolean;
  allowDelete?: boolean;
  placeholder?: string;
  defaultValue?: string;
  defaultItems?: string[];
  submitTestId?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onAddItem?: (item: string) => void;
  onRemoveItem?: (item: string) => void;
  onChangeItems?: (items: string[]) => void;
  onSelectItem?: (index: number) => void;
  ref?: React.Ref<HTMLInputElement>;
};

export const InputWithList: React.FC<InputWithListProps> = React.forwardRef<
  HTMLInputElement,
  InputWithListProps
>((props, ref) => {
  const {
    isInvalid = false,
    isInvalidInput = false,
    isDisabled = false,
    allowDelete = true,
    defaultValue,
    defaultItems = [],
    placeholder = 'Escreva aqui',
    label = 'Salvar',
    submitTestId,
    onChange,
    onFocus,
    onBlur,
    onAddItem,
    onRemoveItem,
    onChangeItems,
    onSelectItem,
    ...inputProps
  } = props;
  const [items, setItems] = useState<string[]>(defaultItems);
  const [isValid, setIsValid] = useState<boolean>(!!defaultValue);
  const inputRef = useRef<HTMLInputElement>();
  const refs = useMergeRefs(inputRef, ref);

  const { t } = useTranslation('common');

  const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.preventDefault();

    onFocus?.(event);
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const value = inputRef?.current?.value;
    setIsValid(!!value?.length);

    onChange?.(event);
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    event.preventDefault();

    onBlur?.(event);
  };

  const handleOnAddItem = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const value = inputRef?.current?.value;

    if (!value) return;
    if (items.find((item) => item.toLowerCase() === value.trim().toLowerCase())) {
      setIsValid(false);
      if (inputRef.current) inputRef.current.value = '';
      return;
    }

    const newItems = [...items, value as string];

    onChangeItems?.(newItems);
    setItems(newItems);
    onAddItem?.(value as string);
    setIsValid(false);

    if (inputRef.current) inputRef.current.value = '';
  };

  const handleOnRemoveItem = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    event.preventDefault();

    onRemoveItem?.(items[index]);

    items.splice(index, 1);
    onChangeItems?.([...items]);
    setItems([...items]);
  };

  const handleOnSelectItem = (index: number) => (e: React.MouseEvent) => {
    e.preventDefault();
    onSelectItem?.(index);
  };

  const onEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e?.key === 'Enter') handleOnAddItem(e as unknown as React.MouseEvent<HTMLButtonElement>);
  };

  const allowDeleteButton = (item: string) =>
    allowDelete || (defaultItems?.length > 0 && !defaultItems.includes(item) && !allowDelete);

  useEffect(() => {
    const addedItems = items.slice(defaultItems.length);
    setItems([...defaultItems, ...addedItems]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(defaultItems)]);

  return (
    <S.Wrapper>
      <InputGroup>
        <S.Input
          {...inputProps}
          ref={refs}
          isInvalid={isInvalid || isInvalidInput}
          isDisabled={isDisabled && allowDelete}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onKeyDown={onEnterPress}
          onFocus={handleOnFocus}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
        />
        <InputRightElement>
          <S.Button
            data-testid={submitTestId}
            onClick={handleOnAddItem}
            isDisabled={isDisabled || isInvalidInput || !isValid}
          >
            <Typography variant="subtitle" color="neutral0" weight="bold">
              {label}
            </Typography>
          </S.Button>
        </InputRightElement>
      </InputGroup>
      {items?.length > 0 && (
        <S.ListWrapper>
          <S.Title variant="body2" weight="bold">
            {t('components.InputWithList.title')}
          </S.Title>
          <S.List>
            {items?.map((item, index) => (
              <S.Item
                key={index}
                justify="space-between"
                align="center"
                onClick={handleOnSelectItem(index)}
              >
                <S.Text>
                  {index + 1}. {item}
                </S.Text>
                {!isDisabled && allowDeleteButton(item) && (
                  <S.CloseButton
                    aria-label={`Remover item ${index + 1}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOnRemoveItem(e, index);
                    }}
                  >
                    <InterfaceCross width={20} height={20} color="#FFF" />
                  </S.CloseButton>
                )}
              </S.Item>
            ))}
          </S.List>
        </S.ListWrapper>
      )}
    </S.Wrapper>
  );
});
