import { gql } from '@apollo/client';
import { SESSION_CARD_FRAGMENT } from '../fragments/sessionCardFragment';

export const GET_USER_SESSIONS = gql`
  ${SESSION_CARD_FRAGMENT}

  query getUserSessions($filters: SessionInputFilters, $orderBy: SessionOrderByInput) {
    user {
      sessions(filters: $filters, orderBy: $orderBy) {
        ...SessionCardFields
        rooms {
          roomNumber
          floor
          startAt
        }
      }
    }
  }
`;
