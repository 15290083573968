import { gql } from '@apollo/client';

export const SESSION_CARD__BY_CLINICAL_CASE_FRAGMENT = gql`
  fragment SessionCardByClinicalCaseFields on Session {
    id
    clinicalCaseId
    clinicalCase {
      id
      name
    }
    location {
      label
      labelDescription
    }
    finished
    status
    statusDescription
    sessionTypeDescription
    sessionType
    discipline
    observation
    finalStartedAt
    finalEndedAt
    startScheduledAt
    endScheduledAt
    createdAt
    updatedAt
    location {
      labelDescription
    }
    clinicians {
      id
      name
    }
    rooms {
      roomNumber
      floor
      startAt
    }
    createdBy {
      id
      name
    }
    updatedBy {
      id
      name
    }
    __typename
  }
`;
