import { gql } from '@apollo/client';

export const GET_OBJECTIVES_FOR_EVOLUTION_CHECK = gql`
  query getObjectivesByClinicalCase($clinicalCaseId: ID!, $filters: ObjectivesInputFilter) {
    objectives(clinicalCaseId: $clinicalCaseId, filters: $filters) {
      id
      description
      programs {
        ... on AbaProgram {
          discipline
          targets {
            id
            name
            status
            data {
              ... on TrialTargetData {
                currentPromptName
              }
            }
          }
          configuration {
            ... on TrialConfiguration {
              promptSchedule {
                id
                name
                prompts {
                  id
                  name
                }
              }
            }
          }
        }
        ... on OccupationalTherapyProgram {
          discipline
          legacy
        }
        ... on SpeechTherapyProgram {
          discipline
          legacy
        }
        ... on SymbolicPlayProgram {
          programType
        }
      }
      protocolItem {
        id
        libraryObjective {
          id
          currentEvolutionCheckConfiguration {
            id
            maxMeasurements
            prerequisites {
              name
            }
            instructions
          }
        }
      }
    }
  }
`;
