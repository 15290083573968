import { GeneralWallet, pxToRem, styled } from '@genialcare/atipico-react';
import {
  GeneralHome as IconHomeSvg,
  InterfaceSettingsAlt as IconSettingsSvg,
  GeneralCalendar as IconCalendarSvg,
  FileClipboardAlt as IconTasksSvg,
  StatusInfoCircle as IconPendenciesSvg,
  GeneralClock as IconHoursSvg,
  StatusBookOpen as IconStatusBookOpenSvg,
  GeneralChartPie as IconGaneralChartPieSvg,
  StatusHeart as StatusHeart,
} from '@genialcare/atipico-react';
import { menuIconStyles } from '../SideMenu.styles';

export const List = styled('div', {
  transition: '0.5s',
  padding: `${pxToRem(52, 12)} ${pxToRem(8, 12)}`,
  alignSelf: 'center',
  width: '100%',
  display: 'none',
  flexDirection: 'column',

  '@lg': {
    display: 'flex',
    borderTop: '1px solid $neutral100',
    padding: `${pxToRem(32)} 0`,
    alignItems: 'center',
  },

  variants: {
    isOpen: {
      true: {
        display: 'flex',
      },
    },
  },
});

export const IconHome = styled(IconHomeSvg, {
  ...menuIconStyles,
});

export const IconSettings = styled(IconSettingsSvg, {
  ...menuIconStyles,
});

export const IconCalendar = styled(IconCalendarSvg, {
  ...menuIconStyles,
});

export const IconsTasks = styled(IconTasksSvg, {
  ...menuIconStyles,
});

export const IconPendencies = styled(IconPendenciesSvg, {
  ...menuIconStyles,
});

export const IconHours = styled(IconHoursSvg, {
  ...menuIconStyles,
});

export const IconStatusBookOpen = styled(IconStatusBookOpenSvg, {
  ...menuIconStyles,
});

export const IconStatusHeart = styled(StatusHeart, {
  ...menuIconStyles,
});

export const IconGaneralChartPie = styled(IconGaneralChartPieSvg, {
  ...menuIconStyles,
});

export const IconWallet = styled(GeneralWallet, {
  ...menuIconStyles,
});
