import React from 'react';
import {
  PLANNING_BY_CLINICAL_CASE_PAGE,
  CLINICAL_GUIDANCE_PLANNING,
  THERAPIST_AREA,
  USER_INVOICE_STATEMENTS,
} from 'constants/flags';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { MenuItem } from './MenuItem';
import * as S from './MenuContent.styles';
import { useUserData } from 'contexts/user';

type MenuContentProps = {
  isOpen: boolean;
  isClinicalCaseOwner: boolean;
};

export const MenuContent: React.FC<MenuContentProps> = ({ isOpen, isClinicalCaseOwner }) => {
  const { email } = useUserData();
  const featureFlagAttributes = {
    attributes: {
      user_email: email,
    },
  };

  const clinicalGuidancePlanningEnabled = useFeatureFlag(
    CLINICAL_GUIDANCE_PLANNING,
    featureFlagAttributes,
  );
  const planningByClinicalCaseEnabled = useFeatureFlag(
    PLANNING_BY_CLINICAL_CASE_PAGE,
    featureFlagAttributes,
  );
  const userInvoiceStatementsEnabled = useFeatureFlag(
    USER_INVOICE_STATEMENTS,
    featureFlagAttributes,
  );
  const therapistAreaEnabled = useFeatureFlag(THERAPIST_AREA, featureFlagAttributes);

  const showPlanningByClinicalCaseOwner = isClinicalCaseOwner && clinicalGuidancePlanningEnabled;

  return (
    <S.List as="ol" isOpen={isOpen} data-testid="side-menu-items" data-helphero="side-menu-items">
      <MenuItem to="/panel/home" Icon={S.IconHome} text="Início" isOpen={isOpen} />
      {showPlanningByClinicalCaseOwner && (
        <MenuItem
          to="/panel/users/clinical-guidance-planning"
          Icon={S.IconStatusBookOpen}
          text="Planejamento"
          isOpen={isOpen}
          data-testid="clinical-guidance-planning-link"
        />
      )}

      {!isClinicalCaseOwner && (
        <>
          {!planningByClinicalCaseEnabled && (
            <MenuItem
              to="/panel/users/session-planning"
              Icon={S.IconStatusBookOpen}
              text="Planejamento"
              isOpen={isOpen}
              data-testid="session-planning-link"
            />
          )}
          {planningByClinicalCaseEnabled && (
            <MenuItem
              to="/panel/users/planning"
              Icon={S.IconStatusBookOpen}
              text="Planejamento"
              isOpen={isOpen}
              data-testid="clinical-case-planning-link"
            />
          )}
        </>
      )}

      <MenuItem
        to="/panel/users/pendencies"
        Icon={S.IconPendencies}
        text="Pendências"
        isOpen={isOpen}
        data-testid="user-pendencies-link"
      />
      <MenuItem
        to="/panel/users/sessions"
        Icon={S.IconCalendar}
        text="Sessões"
        isOpen={isOpen}
        data-testid="user-sessions-link"
      />

      <MenuItem
        to="/panel/users/tasks"
        Icon={S.IconsTasks}
        text="Tarefas"
        isOpen={isOpen}
        data-testid="user-tasks-link"
      />

      {isClinicalCaseOwner && (
        <MenuItem
          to="/panel/users/family-support-center"
          Icon={S.IconStatusHeart}
          text="Genial Junto"
          isOpen={isOpen}
          data-testid="family-support-center-link"
        />
      )}

      <MenuItem
        to="/panel/users/marketplace"
        Icon={S.IconHours}
        text="Combinação de agendas"
        isOpen={isOpen}
        data-testid="user-marketplace-link"
      />

      {userInvoiceStatementsEnabled && (
        <MenuItem
          to="/panel/users/invoice-statement"
          Icon={S.IconWallet}
          text="Extrato"
          isOpen={isOpen}
          data-testid="user-invoice-statements-link"
        />
      )}

      {therapistAreaEnabled && (
        <MenuItem
          to="/panel/users/therapist-area"
          Icon={S.IconGaneralChartPie}
          text="Dados"
          isOpen={isOpen}
          data-testid="therapist-area-link"
        />
      )}

      <MenuItem to="/panel/settings" Icon={S.IconSettings} text="Configurações" isOpen={isOpen} />
    </S.List>
  );
};
