import React, { PropsWithChildren } from 'react';
import { Typography } from '@genialcare/atipico-react';
import type { CSS } from '@genialcare/atipico-react';
import * as S from './CardTitle.styles';

type CardTitleProps = PropsWithChildren & {
  css?: CSS;
  className?: string;
  overflowTextLineValue?: number;
};

const DEFAULT_OVERFLOW_TEXT_LINE_VALUE = 1;

export const CardTitle: React.FC<CardTitleProps> = ({
  css = {},
  overflowTextLineValue = DEFAULT_OVERFLOW_TEXT_LINE_VALUE,
  className,
  children,
}) => (
  <Typography
    variant="h5"
    weight="bold"
    color="neutral500"
    className={className}
    css={{
      ...css,
      ...S.overflowTextStyle(overflowTextLineValue),
    }}
  >
    {children}
  </Typography>
);
