import { gql } from '@apollo/client';

export const SESSION_CARD_FRAGMENT = gql`
  fragment SessionCardFields on Session {
    id
    clinicalCaseId
    clinicalCase {
      name
    }
    location {
      label
      labelDescription
    }
    status
    statusDescription
    finished
    sessionTypeDescription
    sessionType
    sessionTypeDescription
    discipline
    observation
    finalStartedAt
    finalEndedAt
    clinicians {
      id
      name
    }
    pendencies {
      id
      pendencyType
      pendencyTypeDescription
      category
      categoryDescription
      dueDate
      createdAt
      updatedAt
      context {
        id
        type
        clinicalCase {
          id
          name
          number
        }
      }
      __typename
    }
    __typename
  }
`;
