import { gql } from '@apollo/client';

export const GET_OBJECTIVE_BY_ID = gql`
  query getObjectiveById($id: ID!, $clinicalCaseId: ID!) {
    objective(id: $id, clinicalCaseId: $clinicalCaseId) {
      id
      description
      status
      updatedAt
      protocolItem {
        id
        protocolId
        protocolName
        description
        domain
        subdomain
        subdomainCategory
      }
      skill {
        id
        name
      }
      programs {
        ... on AbaProgram {
          id
          discipline
          name
          instruction
          stimulusDiscrimination
          consequence
          correctionProcedure
          errorCorrection
          independentResponseReinforcement
          promptedResponseReinforcement
          trialsPerSession
          registerType
          activities {
            name
          }
          targets {
            id
            name
            status
            createdAt
            data {
              ... on TrialTargetData {
                currentPromptId
                currentPromptName
              }
            }
          }
          targetCategory {
            name
          }
          configuration {
            ... on TrialConfiguration {
              promptSchedule {
                id
                name
                promotion
                demotion
                sessionInARowToConsider
                prompts {
                  id
                  name
                  abbreviation
                }
              }
            }
            ... on TaskAnalysisConfiguration {
              steps {
                id
                name
                level
              }
            }
          }
        }
        ... on SymbolicPlayProgram {
          id
          programType
          targets {
            id
            name
            status
            createdAt
          }
        }
        ... on OccupationalTherapyProgram {
          id
          legacy
          discipline
          name
          description
          instruction
          sensorialFunctions {
            id
            name
          }
          strategies {
            id
            name
            strategyId
            status
            createdAt
          }
          targets {
            id
            name
            status
            createdAt
          }
        }
        ... on SpeechTherapyProgram {
          id
          legacy
          name
          instruction
          discipline
          description
          skills {
            id
            name
          }
          strategies {
            id
            name
            strategyId
            status
            createdAt
          }
          targets {
            id
            name
            status
            createdAt
          }
          activities {
            name
          }
          possiblePrompts {
            name
          }
        }
      }
      program {
        id
        name
        instruction
        stimulusDiscrimination
        consequence
        correctionProcedure
        errorCorrection
        independentResponseReinforcement
        promptedResponseReinforcement
        trialsPerSession
        registerType
        activities {
          name
        }
        targets {
          id
          name
          status
          createdAt
          data {
            ... on TrialTargetData {
              currentPromptId
              currentPromptName
            }
          }
        }
        targetCategory {
          name
        }
        configuration {
          __typename
          ... on TrialConfiguration {
            promptSchedule {
              id
              name
              prompts {
                id
                name
              }
            }
          }
          ... on TaskAnalysisConfiguration {
            steps {
              id
              name
              level
            }
          }
        }
      }
      strategies {
        id
        name
        abbreviation
      }
    }
  }
`;
