import { pxToRem, styled } from '@genialcare/atipico-react';

export const menuIconStyles = {
  width: pxToRem(19, 12),
  height: '100%',
  fill: '$purple100',

  '@lg': {
    width: pxToRem(21),
  },

  variants: {
    active: {
      true: {
        fill: '$neutral100',
      },
    },
  },
};

export const Menu = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  userSelect: 'none',
  position: 'fixed',
  top: 0,
  left: 0,
  padding: `0 ${pxToRem(18, 12)}`,
  width: 'auto',
  height: pxToRem(52, 12),
  zIndex: 3,
  '@lg': {
    transition: '0.5s',
    height: '100%',
    width: pxToRem(94),
    position: 'fixed',
    border: '1px solid $neutral100',
    backgroundColor: '$neutral0',
    boxShadow: `0px ${pxToRem(4)} ${pxToRem(30)} rgba(0, 0, 0, 0.05)`,
    padding: `${pxToRem(20)} 0 0 0`,
  },

  variants: {
    isOpen: {
      true: {
        width: '100%',
        height: '100%',
        backgroundColor: '$neutral0',

        '@lg': {
          width: pxToRem(300),
        },
      },
    },
  },
});
