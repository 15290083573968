import React from 'react';
import * as CheckboxDefault from '@radix-ui/react-checkbox';
import * as S from './Checkbox.styles';

import { JsxStyleProps } from 'styled-system/types';
import { css } from 'styled-system/css';

type SelectProps = JsxStyleProps & {
  className?: string;
  'data-testid'?: string;
  label: string;
  value?: string;
  id?: string;
  defaultChecked?: boolean;
  checked?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  isInvalid?: boolean;
  cursor?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onCheckedChange?: (checked: boolean) => void;
};

export const Checkbox: React.FC<SelectProps> = React.forwardRef<HTMLDivElement, SelectProps>(
  (props, ref) => {
    const {
      className,
      id,
      label,
      value,
      defaultChecked,
      checked,
      isDisabled,
      isReadOnly,
      onClick,
      isInvalid,
      onCheckedChange,
      'data-testid': dataTestId,
      cursor = 'default',
      ...rest
    } = props;

    return (
      <S.Flex alignItems="center" className={className} {...rest}>
        <S.Label htmlFor={id || label}>
          <S.Root
            data-testid={dataTestId}
            value={value || label}
            defaultChecked={defaultChecked}
            id={id || label}
            disabled={isDisabled}
            aria-invalid={isInvalid}
            aria-readonly={isReadOnly}
            onClick={onClick}
            checked={checked}
            onCheckedChange={onCheckedChange}
            className={css({ cursor: cursor })}
          >
            <CheckboxDefault.Indicator ref={ref} />
            <S.LabelText>{label}</S.LabelText>
          </S.Root>
        </S.Label>
      </S.Flex>
    );
  },
);
